// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { GloveRow } from '../typings';

const data = [
  {
    "id": "882",
    "modelId": "882",
    "asset": {
      "gloveAssetId": "882",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.882.description')
  },
  {
    "id": "882PT",
    "modelId": "882",
    "asset": {
      "gloveAssetId": "882",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.882PT.description')
  },
  {
    "id": "REV204",
    "modelId": "REV204",
    "asset": {
      "gloveAssetId": "REV204",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": true
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": false,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": true
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": true,
        "ProI": false,
        "ProI2x": true,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": true,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": true,
        "ProV": false,
        "ProV7x": true,
        "OnePS": false,
        "OnePS8x": true,
        "TwoPS": false,
        "TwoPS9x": true,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": true
      },
      "shellBackMaterialHands": {
        "bbColumbia-gradient": "neither",
        "black-geo": "right",
        "black-gradient": "neither",
        "black-shatter": "right",
        "blackSlime-gradient": "neither",
        "blueGreen-gradient": "right",
        "columbiaBlue-geo": "neither",
        "navy-geo": "right",
        "navy-shatter": "neither",
        "navyGold-gradient": "right",
        "navyScarlet-shatter": "right",
        "purple-geo": "neither",
        "redBlack-shatter": "right",
        "royal-geo": "right",
        "navyScarlet-geo": "right",
        "purplePink-gradient": "right",
        "smileys": "right",
        "pinkBlueTieDye": "right",
        "usa": "right",
        "camo": "right"
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI2x"
    },
    "description": t('rawlingsGloves:sheets.gloves.REV204.description')
  },
  {
    "id": "204",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "hswGloveAssetId": "204HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.204.description')
  },
  {
    "id": "204U",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": true,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.204U.description')
  },
  {
    "id": "204F",
    "modelId": "200FB",
    "asset": {
      "gloveAssetId": "200FB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.204F.description')
  },
  {
    "id": "204CFB",
    "modelId": "204CFB",
    "asset": {
      "gloveAssetId": "204CFB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.204CFB.description')
  },
  {
    "id": "204W",
    "modelId": "200WT",
    "asset": {
      "gloveAssetId": "200WT",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "4",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.204W.description')
  },
  {
    "id": "205",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "hswGloveAssetId": "204HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.205.description')
  },
  {
    "id": "205CFB",
    "modelId": "204CFB",
    "asset": {
      "gloveAssetId": "204CFB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.205CFB.description')
  },
  {
    "id": "REV205",
    "modelId": "REV204",
    "asset": {
      "gloveAssetId": "REV204",
      "hswGloveAssetId": "REV204",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": true
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": true
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": true,
        "ProI": false,
        "ProI2x": true,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": true,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": true,
        "ProV": false,
        "ProV7x": true,
        "OnePS": false,
        "OnePS8x": true,
        "TwoPS": false,
        "TwoPS9x": true,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": true
      },
      "shellBackMaterialHands": {
        "bbColumbia-gradient": "right",
        "blueGreen-gradient": "neither",
        "columbiaBlue-geo": "right",
        "navy-shatter": "neither",
        "navyGold-gradient": "neither",
        "purple-geo": "neither",
        "redBlack-shatter": "neither",
        "navyScarlet-geo": "right",
        "purplePink-gradient": "right",
        "smileys": "neither"
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI2x"
    },
    "description": t('rawlingsGloves:sheets.gloves.REV205.description')
  },
  {
    "id": "205FS",
    "modelId": "205FS",
    "asset": {
      "gloveAssetId": "205FS",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.205FS.description')
  },
  {
    "id": "205W",
    "modelId": "200WT",
    "asset": {
      "gloveAssetId": "200WT",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "4",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.205W.description')
  },
  {
    "id": "206",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "hswGloveAssetId": "204HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.206.description')
  },
  {
    "id": "206F",
    "modelId": "200FB",
    "asset": {
      "gloveAssetId": "200FB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.206F.description')
  },
  {
    "id": "206CFB",
    "modelId": "204CFB",
    "asset": {
      "gloveAssetId": "204CFB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.206CFB.description')
  },
  {
    "id": "207",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "hswGloveAssetId": "204HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.207.description')
  },
  {
    "id": "REV207",
    "modelId": "REV204",
    "asset": {
      "gloveAssetId": "REV204",
      "hswGloveAssetId": "REV204",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": true
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": true
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": true,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": true,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": true,
        "ProV": false,
        "ProV7x": true,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": true,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": true
      },
      "shellBackMaterialHands": {
        "bbColumbia-gradient": "neither",
        "black-geo": "neither",
        "black-gradient": "neither",
        "blackSlime-gradient": "neither",
        "blueGreen-gradient": "neither",
        "columbiaBlue-geo": "neither",
        "navy-geo": "neither",
        "navy-shatter": "neither",
        "navyGold-gradient": "neither",
        "purple-geo": "neither",
        "redBlack-shatter": "neither",
        "royal-geo": "neither",
        "navyScarlet-geo": "neither",
        "purplePink-gradient": "neither",
        "smileys": "neither"
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI2x"
    },
    "description": t('rawlingsGloves:sheets.gloves.REV207.description')
  },
  {
    "id": "208",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.208.description')
  },
  {
    "id": "2172",
    "modelId": "217",
    "asset": {
      "gloveAssetId": "217",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.2172.description')
  },
  {
    "id": "2174",
    "modelId": "217",
    "asset": {
      "gloveAssetId": "217",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.2174.description')
  },
  {
    "id": "2175",
    "modelId": "217",
    "asset": {
      "gloveAssetId": "217",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.2175.description')
  },
  {
    "id": "3028",
    "modelId": "302CV",
    "asset": {
      "gloveAssetId": "302CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3028.description')
  },
  {
    "id": "3028U",
    "modelId": "302CV",
    "asset": {
      "gloveAssetId": "302CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": true,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3028U.description')
  },
  {
    "id": "3029",
    "modelId": "302CV",
    "asset": {
      "gloveAssetId": "302CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3029.description')
  },
  {
    "id": "3029F",
    "modelId": "302FB",
    "asset": {
      "gloveAssetId": "302FB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3029F.description')
  },
  {
    "id": "3029CFB",
    "modelId": "3029CFB",
    "asset": {
      "gloveAssetId": "3029CFB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3029CFB.description')
  },
  {
    "id": "27HF9",
    "modelId": "302FB",
    "asset": {
      "gloveAssetId": "302FB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.27HF9.description')
  },
  {
    "id": "3030",
    "modelId": "303",
    "asset": {
      "gloveAssetId": "303",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3030.description')
  },
  {
    "id": "3039",
    "modelId": "303",
    "asset": {
      "gloveAssetId": "303",
      "hswGloveAssetId": "3039HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3039.description')
  },
  {
    "id": "3039FS",
    "modelId": "3039FS",
    "asset": {
      "gloveAssetId": "3039FS",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3039FS.description')
  },
  {
    "id": "REV3039",
    "modelId": "REV3039",
    "asset": {
      "gloveAssetId": "REV3039",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": false,
        "hoh": false,
        "gxle": false,
        "rla": false,
        "rev1x": true
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": true
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": true,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": true,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": true,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      },
      "shellBackMaterialHands": {
        "bbColumbia-gradient": "neither",
        "black-gradient": "neither",
        "blackSlime-gradient": "neither",
        "blueGreen-gradient": "neither",
        "columbiaBlue-geo": "neither",
        "navyGold-gradient": "neither",
        "purple-geo": "neither",
        "redBlack-shatter": "neither",
        "navyScarlet-geo": "right",
        "smileys": "neither"
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI2x"
    },
    "description": t('rawlingsGloves:sheets.gloves.REV3039.description')
  },
  {
    "id": "312",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1",
          "3"
        ],
        "deco": [
          "1",
          "3"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.312.description')
  },
  {
    "id": "312U",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1",
          "3"
        ],
        "deco": [
          "1",
          "3"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": true,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.312U.description')
  },
  {
    "id": "314",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "hswGloveAssetId": "314HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1",
          "3"
        ],
        "deco": [
          "1",
          "3"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.314.description')
  },
  {
    "id": "315",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1",
          "3"
        ],
        "deco": [
          "1",
          "3"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.315.description')
  },
  {
    "id": "316",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1",
          "3"
        ],
        "deco": [
          "1",
          "3"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.316.description')
  },
  {
    "id": "3319",
    "modelId": "3319",
    "asset": {
      "gloveAssetId": "3319",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1",
          "3"
        ],
        "deco": [
          "1",
          "3"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.3319.description')
  },
  {
    "id": "5028F",
    "modelId": "502",
    "asset": {
      "gloveAssetId": "502",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.5028F.description')
  },
  {
    "id": "5048",
    "modelId": "504",
    "asset": {
      "gloveAssetId": "504",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.5048.description')
  },
  {
    "id": "6019",
    "modelId": "600",
    "asset": {
      "gloveAssetId": "600",
      "hswGloveAssetId": "6019HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "Trapeze"
    },
    "description": t('rawlingsGloves:sheets.gloves.6019.description')
  },
  {
    "id": "6019FS",
    "modelId": "6019FS",
    "asset": {
      "gloveAssetId": "6019FS",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "Trapeze"
    },
    "description": t('rawlingsGloves:sheets.gloves.6019FS.description')
  },
  {
    "id": "601PT",
    "modelId": "600",
    "asset": {
      "gloveAssetId": "600",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "Trapeze"
    },
    "description": t('rawlingsGloves:sheets.gloves.601PT.description')
  },
  {
    "id": "6010",
    "modelId": "600",
    "asset": {
      "gloveAssetId": "600",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "Trapeze"
    },
    "description": t('rawlingsGloves:sheets.gloves.6010.description')
  },
  {
    "id": "JD8",
    "modelId": "JD",
    "asset": {
      "gloveAssetId": "JD",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.JD8.description')
  },
  {
    "id": "JD9",
    "modelId": "JD",
    "asset": {
      "gloveAssetId": "JD",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.JD9.description')
  },
  {
    "id": "JD0",
    "modelId": "JD",
    "asset": {
      "gloveAssetId": "JD",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.JD0.description')
  },
  {
    "id": "NP2",
    "modelId": "NP",
    "asset": {
      "gloveAssetId": "NP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.NP2.description')
  },
  {
    "id": "NP4",
    "modelId": "NP",
    "asset": {
      "gloveAssetId": "NP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.NP4.description')
  },
  {
    "id": "NP5",
    "modelId": "NP",
    "asset": {
      "gloveAssetId": "NP",
      "hswGloveAssetId": "NP5HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.NP5.description')
  },
  {
    "id": "NP6",
    "modelId": "NP",
    "asset": {
      "gloveAssetId": "NP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": true,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.NP6.description')
  },
  {
    "id": "NP7",
    "modelId": "NP",
    "asset": {
      "gloveAssetId": "NP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.NP7.description')
  },
  {
    "id": "SC1",
    "modelId": "SC1",
    "asset": {
      "gloveAssetId": "SC1",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.SC1.description')
  },
  {
    "id": "TT2",
    "modelId": "TT2",
    "asset": {
      "gloveAssetId": "TT2",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.TT2.description')
  },
  {
    "id": "YJN1",
    "modelId": "YJN1",
    "asset": {
      "gloveAssetId": "YJN1",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.YJN1.description')
  },
  {
    "id": "YPT110",
    "modelId": "YPT110",
    "asset": {
      "gloveAssetId": "YPT110",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.YPT110.description')
  },
  {
    "id": "YPT115",
    "modelId": "YPT110",
    "asset": {
      "gloveAssetId": "YPT110",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.YPT115.description')
  },
  {
    "id": "YPT175",
    "modelId": "YPT110",
    "asset": {
      "gloveAssetId": "YPT110",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.YPT175.description')
  },
  {
    "id": "YPT120",
    "modelId": "YPT110",
    "asset": {
      "gloveAssetId": "YPT110",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.YPT120.description')
  },
  {
    "id": "MT27",
    "modelId": "600",
    "asset": {
      "gloveAssetId": "600",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "Trapeze"
    },
    "description": t('rawlingsGloves:sheets.gloves.MT27.description')
  },
  {
    "id": "MT27CFB",
    "modelId": "MT27CFB",
    "asset": {
      "gloveAssetId": "MT27CFB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": true,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "Trapeze"
    },
    "description": t('rawlingsGloves:sheets.gloves.MT27CFB.description')
  },
  {
    "id": "KB17",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "hswGloveAssetId": "204HSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.KB17.description')
  },
  {
    "id": "FL12",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.FL12.description')
  },
  {
    "id": "CS5",
    "modelId": "200CV",
    "asset": {
      "gloveAssetId": "200CV",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "5",
        "6",
        "7"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.CS5.description')
  },
  {
    "id": "BH34",
    "modelId": "303",
    "asset": {
      "gloveAssetId": "303",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": true,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": true,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.BH34.description')
  },
  {
    "id": "120SB",
    "modelId": "125SB",
    "asset": {
      "gloveAssetId": "125SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.120SB.description')
  },
  {
    "id": "120FS",
    "modelId": "125FS",
    "asset": {
      "gloveAssetId": "125FS",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.120FS.description')
  },
  {
    "id": "125KR",
    "modelId": "125KR",
    "asset": {
      "gloveAssetId": "125KR",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.125KR.description')
  },
  {
    "id": "125SB",
    "modelId": "125SB",
    "asset": {
      "gloveAssetId": "125SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.125SB.description')
  },
  {
    "id": "125FS",
    "modelId": "125FS",
    "asset": {
      "gloveAssetId": "125FS",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.125FS.description')
  },
  {
    "id": "1275SB",
    "modelId": "125SB",
    "asset": {
      "gloveAssetId": "125SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.1275SB.description')
  },
  {
    "id": "130SB",
    "modelId": "125KR",
    "asset": {
      "gloveAssetId": "125KR",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.130SB.description')
  },
  {
    "id": "314SBPT",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": true,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": false,
        "ModSinglePost": true,
        "VerticalHB": false,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.314SBPT.description')
  },
  {
    "id": "315SB",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.315SB.description')
  },
  {
    "id": "315SBPT",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.315SBPT.description')
  },
  {
    "id": "715SB",
    "modelId": "715SB",
    "asset": {
      "gloveAssetId": "715SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.715SB.description')
  },
  {
    "id": "716SB",
    "modelId": "715SB",
    "asset": {
      "gloveAssetId": "715SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.716SB.description')
  },
  {
    "id": "316SB",
    "modelId": "315",
    "asset": {
      "gloveAssetId": "315",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1",
        "2",
        "3",
        "5",
        "6",
        "7",
        "8"
      ],
      "trimOptionsWithShellBack": {
        "pro": [
          "1"
        ],
        "deco": [
          "1"
        ]
      },
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.316SB.description')
  },
  {
    "id": "566",
    "modelId": "566",
    "asset": {
      "gloveAssetId": "560",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "lacesSuffix": "-566",
      "webIds": {
        "SingleP": true,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": true,
        "ProH6x": false,
        "ProV": true,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.566.description')
  },
  {
    "id": "568",
    "modelId": "568",
    "asset": {
      "gloveAssetId": "560",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "lacesSuffix": "-568",
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": true,
        "ModTrapeze": true,
        "ModTrapeze4x": false,
        "SinglePDB": true,
        "ProH": true,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": true,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": true,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.568.description')
  },
  {
    "id": "CMHCB2",
    "modelId": "CMHCB2",
    "asset": {
      "gloveAssetId": "CMHCB2",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": true,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "HorizontalXLaces"
    },
    "description": t('rawlingsGloves:sheets.gloves.CMHCB2.description')
  },
  {
    "id": "DCT",
    "modelId": "DCT",
    "asset": {
      "gloveAssetId": "DCT",
      "hswGloveAssetId": "DCTHSW",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    },
    "description": t('rawlingsGloves:sheets.gloves.DCT.description')
  },
  {
    "id": "FM16",
    "modelId": "FM16",
    "asset": {
      "gloveAssetId": "FM16",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ModProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.FM16.description')
  },
  {
    "id": "FM18",
    "modelId": "FM18",
    "asset": {
      "gloveAssetId": "FM18",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    },
    "description": t('rawlingsGloves:sheets.gloves.FM18.description')
  },
  {
    "id": "FM19",
    "modelId": "FM19",
    "asset": {
      "gloveAssetId": "FM19",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ModProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.FM19.description')
  },
  {
    "id": "FM20XL",
    "modelId": "FM20XL",
    "asset": {
      "gloveAssetId": "FM20XL",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    },
    "description": t('rawlingsGloves:sheets.gloves.FM20XL.description')
  },
  {
    "id": "SC21",
    "modelId": "FM20XL",
    "asset": {
      "gloveAssetId": "FM20XL",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_25",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    },
    "description": t('rawlingsGloves:sheets.gloves.SC21.description')
  },
  {
    "id": "SC5",
    "modelId": "SC5",
    "asset": {
      "gloveAssetId": "SC5",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ModProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.SC5.description')
  },
  {
    "id": "TM",
    "modelId": "FM20XL",
    "asset": {
      "gloveAssetId": "FM20XL",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    },
    "description": t('rawlingsGloves:sheets.gloves.TM.description')
  },
  {
    "id": "AR44",
    "modelId": "CMHCB2",
    "asset": {
      "gloveAssetId": "CMHCB2",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": true,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": true,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "HorizontalXLaces"
    },
    "description": t('rawlingsGloves:sheets.gloves.AR44.description')
  },
  {
    "id": "FM19SB",
    "modelId": "FM19SB",
    "asset": {
      "gloveAssetId": "FM19SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": true,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    },
    "description": t('rawlingsGloves:sheets.gloves.FM19SB.description')
  },
  {
    "id": "TM8SB",
    "modelId": "TM8SB",
    "asset": {
      "gloveAssetId": "TM8SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ModProH"
    },
    "description": t('rawlingsGloves:sheets.gloves.TM8SB.description')
  },
  {
    "id": "CM33",
    "modelId": "CM33",
    "asset": {
      "gloveAssetId": "CM33",
      "hswGloveAssetId": "CM43HSW",
      "useStitchingInsteadOfWelting": true
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "33_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM33.description')
  },
  {
    "id": "CM33U",
    "modelId": "CM33",
    "asset": {
      "gloveAssetId": "CM33",
      "useStitchingInsteadOfWelting": true
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": true,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "33_00",
      "pricingSchemeId": "regular",
      "isHidden": true
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM33U.description')
  },
  {
    "id": "CM32PT",
    "modelId": "CM33",
    "asset": {
      "gloveAssetId": "CM33",
      "useStitchingInsteadOfWelting": true
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": false,
        "narrow": false,
        "contour": false,
        "proTaper": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "32_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM32PT.description')
  },
  {
    "id": "CM43",
    "modelId": "CM43",
    "asset": {
      "gloveAssetId": "CM43",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "34_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM43.description')
  },
  {
    "id": "CM41",
    "modelId": "CM41",
    "asset": {
      "gloveAssetId": "CM41",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "34_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM41.description')
  },
  {
    "id": "JP20",
    "modelId": "JP20",
    "asset": {
      "gloveAssetId": "JP20",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "32_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.JP20.description')
  },
  {
    "id": "PL31",
    "modelId": "PL31",
    "asset": {
      "gloveAssetId": "PL31",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "FB",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "32_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.PL31.description')
  },
  {
    "id": "SP13",
    "modelId": "SP13",
    "asset": {
      "gloveAssetId": "SP13",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": true,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "32_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.SP13.description')
  },
  {
    "id": "YM4",
    "modelId": "CM41",
    "asset": {
      "gloveAssetId": "CM41",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": true,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": true,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "34_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "OnePSCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.YM4.description')
  },
  {
    "id": "CM33FP",
    "modelId": "CM33FP",
    "asset": {
      "gloveAssetId": "CM33FP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": true,
        "TwoPSCatcher": true,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "33_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProHCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM33FP.description')
  },
  {
    "id": "CM34FP",
    "modelId": "CM33FP",
    "asset": {
      "gloveAssetId": "CM33FP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": true,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": true,
        "TwoPSCatcher": true,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "34_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProHCatcher"
    },
    "description": t('rawlingsGloves:sheets.gloves.CM34FP.description')
  },
  {
    "id": "200TR",
    "modelId": "200TR",
    "asset": {
      "gloveAssetId": "200TR",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": true
      },
      "fingerIds": {
        "index": false,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": true,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "9_50",
      "pricingSchemeId": "training",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    },
    "description": t('rawlingsGloves:sheets.gloves.200TR.description')
  },
  {
    "id": "FL12TR",
    "modelId": "FL12TR",
    "asset": {
      "gloveAssetId": "FL12TR",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "baseball",
      "shellBackId": "CV",
      "leatherId": {
        "kip": true,
        "hoh": true,
        "gxle": false,
        "rla": false,
        "rev1x": false
      },
      "fitIds": {
        "standard": true,
        "narrow": false,
        "contour": false,
        "proTaper": false
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false,
        "rev1x": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": true
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "isWeltingPalmDisabled": false,
      "isWeltingBackDisabled": false,
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "SingleP1x": false,
        "ProI": false,
        "ProI2x": false,
        "Basket": false,
        "ModTrapeze": false,
        "ModTrapeze4x": false,
        "SinglePDB": false,
        "ProH": false,
        "ProH6x": false,
        "ProV": false,
        "ProV7x": false,
        "OnePS": false,
        "OnePS8x": false,
        "TwoPS": false,
        "TwoPS9x": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "DoubleLacedBasket18x": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "Laced2PS": false,
        "Laced1PS": false,
        "SplitSingleP32x": false
      }
    },
    "props": {
      "sizeId": "28_00",
      "pricingSchemeId": "training",
      "isHidden": false
    },
    "description": t('rawlingsGloves:sheets.gloves.FL12TR.description')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveRow[]>> = data;

export type Glove = typeof typed[number];

export const GLOVE_INDEX_KEY = "id";
export type GloveIndexKey = "id";
export type GloveId = Glove["id"];

let i = 0;
export const GLOVE_DICT = {
  "882": typed[i++],
  "882PT": typed[i++],
  "REV204": typed[i++],
  "204": typed[i++],
  "204U": typed[i++],
  "204F": typed[i++],
  "204CFB": typed[i++],
  "204W": typed[i++],
  "205": typed[i++],
  "205CFB": typed[i++],
  "REV205": typed[i++],
  "205FS": typed[i++],
  "205W": typed[i++],
  "206": typed[i++],
  "206F": typed[i++],
  "206CFB": typed[i++],
  "207": typed[i++],
  "REV207": typed[i++],
  "208": typed[i++],
  "2172": typed[i++],
  "2174": typed[i++],
  "2175": typed[i++],
  "3028": typed[i++],
  "3028U": typed[i++],
  "3029": typed[i++],
  "3029F": typed[i++],
  "3029CFB": typed[i++],
  "27HF9": typed[i++],
  "3030": typed[i++],
  "3039": typed[i++],
  "3039FS": typed[i++],
  "REV3039": typed[i++],
  "312": typed[i++],
  "312U": typed[i++],
  "314": typed[i++],
  "315": typed[i++],
  "316": typed[i++],
  "3319": typed[i++],
  "5028F": typed[i++],
  "5048": typed[i++],
  "6019": typed[i++],
  "6019FS": typed[i++],
  "601PT": typed[i++],
  "6010": typed[i++],
  "JD8": typed[i++],
  "JD9": typed[i++],
  "JD0": typed[i++],
  "NP2": typed[i++],
  "NP4": typed[i++],
  "NP5": typed[i++],
  "NP6": typed[i++],
  "NP7": typed[i++],
  "SC1": typed[i++],
  "TT2": typed[i++],
  "YJN1": typed[i++],
  "YPT110": typed[i++],
  "YPT115": typed[i++],
  "YPT175": typed[i++],
  "YPT120": typed[i++],
  "MT27": typed[i++],
  "MT27CFB": typed[i++],
  "KB17": typed[i++],
  "FL12": typed[i++],
  "CS5": typed[i++],
  "BH34": typed[i++],
  "120SB": typed[i++],
  "120FS": typed[i++],
  "125KR": typed[i++],
  "125SB": typed[i++],
  "125FS": typed[i++],
  "1275SB": typed[i++],
  "130SB": typed[i++],
  "314SBPT": typed[i++],
  "315SB": typed[i++],
  "315SBPT": typed[i++],
  "715SB": typed[i++],
  "716SB": typed[i++],
  "316SB": typed[i++],
  "566": typed[i++],
  "568": typed[i++],
  "CMHCB2": typed[i++],
  "DCT": typed[i++],
  "FM16": typed[i++],
  "FM18": typed[i++],
  "FM19": typed[i++],
  "FM20XL": typed[i++],
  "SC21": typed[i++],
  "SC5": typed[i++],
  "TM": typed[i++],
  "AR44": typed[i++],
  "FM19SB": typed[i++],
  "TM8SB": typed[i++],
  "CM33": typed[i++],
  "CM33U": typed[i++],
  "CM32PT": typed[i++],
  "CM43": typed[i++],
  "CM41": typed[i++],
  "JP20": typed[i++],
  "PL31": typed[i++],
  "SP13": typed[i++],
  "YM4": typed[i++],
  "CM33FP": typed[i++],
  "CM34FP": typed[i++],
  "200TR": typed[i++],
  "FL12TR": typed[i++]
} as const;

export { typed as GLOVES };
